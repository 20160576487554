/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import folderManagement from "../assets/folder-management.png";
import { MotionAnimate } from "react-motion-animate";
import cakeSite from "../assets/images/cake.png";
import shepherd from "../assets/images/shepherd.png";
import group from "../assets/icons/group.svg";

import calcu from "../assets/images/projects/calcu.png";
import doon from "../assets/images/projects/doon.png";
import bbi from "../assets/images/projects/bbi.png";
import whitley from "../assets/images/projects/whitley.png";
import phl from "../assets/images/projects/phl.png";
import th from "../assets/images/projects/th.png";
import umamia from "../assets/images/projects/umamia.png";
import inventoryRedefine from "../assets/images/projects/inventoryRedefine.png";
import weather from "../assets/images/projects/weather.png";

import { Tooltip } from "react-tooltip";

const Projects = () => {
  const projects = [
    {
      title: "FREE Depreciaton Calculator",
      description:
        "Depreciation is the gradual decline in an asset's value over time due to factors like wear and tear or obsolescence. For example, a phone's value decreases as it ages or is damaged. In accounting, depreciation is a method to spread the cost of an asset over its useful life.",
      imageUrl: calcu,
      link: "https://www.depcal.com/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "DOON",
      description:
        "The Philippines first fully insured P2P online car sharing marketplace. Rent the perfect car for your adventure, or become a host and earn from DOON!",
      imageUrl: doon,
      link: "https://www.doon.ph/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["NextJs", "XANO", "Tailwind Css", "Vercel"],
      withTeam: true,
    },
    {
      title: "BBI Consultancy",
      description:
        "BBI Consultants is a digital brand consulting agency. Specialized in helping micro and small businesses with their products, services, and systems.",
      imageUrl: bbi,
      link: "https://www.bbiconsults.com/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "Whitley Develops",
      description:
        "A web commercial project. A website for a handyman and construction company. Featuring a responsive and nice design.",
      imageUrl: whitley,
      link: "https://www.whitleydevelops.com/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "Construction PHL",
      description:
        "Transforming properties with precision: Mortgage Field Service experts in Preservation and Construction",
      imageUrl: phl,
      link: "https://www.constructionphl.com/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "Talent Hero (AHG Lab)",
      description:
        "A global talent acquisition platform that enables companies to hire anyone in the world through vetted network of recruiters and partner agencies.",
      imageUrl: th,
      link: "https://talenthero.co",
      isDeployed: true,
      hasGIT: false,
      gitLink: "#",
      devStack: ["Vue3", "Tailwind", "XANO"],
      withTeam: true,
    },
    {
      title: "UMAMIA",
      description:
        "A static Japanese Cuisine website. Featuring a responsive and nice design.",
      imageUrl: umamia,
      link: "https://umamia-lake.vercel.app/",
      isDeployed: true,
      hasGIT: false,
      // gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "Cake Boutique",
      description:
        "A static cake website. Featuring a responsive and nice design.",
      imageUrl: cakeSite,
      link: "https://boutique-murex.vercel.app/",
      isDeployed: true,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/boutique",
      devStack: ["Vite", "Vue3", "Tailwind Css", "Vercel"],
      withTeam: false,
    },
    {
      title: "Shepherd",
      description:
        "A Progressive Web App that ables to list, monitor and track progress of an organization`s statistics/key Indicators.",
      imageUrl: shepherd,
      link: "https://attendanceproject-9ea05.web.app/",
      isDeployed: true,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/projectattendance",
      devStack: ["Vue2", "Bootstrap", "Firebase"],
      withTeam: false,
    },
    {
      title: "Point of Sale and Inventory System",
      description:
        "Point of Sale and  inventory system that will provide the necessary storage, handling, processing, and accurate output of information.",
      imageUrl:
        "https://www.cdc.gov/healthypets/images/pets/cute-dog-headshot.jpg?_=42445",
      link: "https://abipos.herokuapp.com/",
      isDeployed: false,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/inventoryRedefine",
      devStack: ["Vue2", "Boostrap", "Laravel", "MySql"],
      withTeam: false,
    },
    {
      title: "Affiliate Network Marketing Platform",
      description:
        "Ecommerce platform that enables businesses to sell products or services to customers over the internet. Also, it is a platform that allows product owners to recruit affiliate marketers to promote their products and services.",
      imageUrl:
        "https://hips.hearstapps.com/hmg-prod/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=0.752xw:1.00xh;0.175xw,0&resize=1200:*",
      link: "#",
      isDeployed: false,
      hasGIT: true,
      gitLink: "https://github.com/BarryMejico/stabilimenta_ecomersSite",
      devStack: ["Vue2", "Boostrap", "Laravel", "MySql"],
      withTeam: true,
    },
    {
      title: "Barangay Information System",
      description:
        "Designed and developed to streamline and automate various administrative processes and information management within a barangay.",
      imageUrl:
        "https://www.cdc.gov/healthypets/images/pets/cute-dog-headshot.jpg?_=42445",
      link: "#",
      isDeployed: false,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/misbrgy62",
      devStack: ["Vue3", "Tailwind", "XANO"],
      withTeam: false,
    },
    {
      title: "Simple Weather Forecast",
      description:
        "Predicts the weather conditions for a specific location and time. Helps you plan your day and stay safe by knowing what to expect. There`s also an option where you can search for a specific location and returns the weather details of that place.",
      imageUrl: weather,
      link: "http://absweather-app.surge.sh/",
      isDeployed: true,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/weather-app/tree/master",
      devStack: [
        "HTML",
        "CSS",
        "JavaScript",
        "Bootstrap",
        "OpenWeatherMap API",
      ],
      withTeam: false,
    },
    {
      title: "Open Source Project - Point of sale and inventory system",
      description:
        "A fully functional Open-Source Point of Sale (POS) and Inventory System made from Laravel8 + Vue2 + AdminLTE 3 based for SPA Application.",
      imageUrl: inventoryRedefine,
      // link: "https://talenthero.co",
      isDeployed: false,
      hasGIT: true,
      gitLink: "https://github.com/abi-collab/open-pos-inventory-laravelvue",
      devStack: ["Vue2", "AdminLTE 3", "Boostrap", "Laravel", "MySql"],
      withTeam: false,
    },
  ];

  return (
    <div className="my-24">
      <MotionAnimate
        animation="fadeInUp"
        reset={false}
        distance={200}
        speed={2}
      >
        <h1 className="text-2xl font-bold mb-12 flex gap-4 h-12">
          <img
            src={folderManagement}
            alt="education icon"
            className="inline-block webb bg-gray-200 p-2 rounded-xl border-2 border-gray-400"
          />
          <div className="grid place-items-center">Projects</div>
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
          {projects.map((project, index) => (
           <div
           className={`rounded shadow-md border-2 border-gray-500 hover:border-white hover:shadow hover:scale-110 grid place-items-stretch transition-transform duration-200 ease-in-out`}
           style={{
             backgroundImage: `url(${project.imageUrl})`,
             backgroundSize: 'cover',
             backgroundPosition: 'center',
           }}
         >
              {/* <img src={project.imageUrl} alt={project.title} className="w-full h-48 object-cover mb-2" /> */}
              <div className="rounded pt-4 px-6 relative bg-gradient-to-br from-[#333] via-[#333] to-transparent hover:bg-transparent">
                <div className="pb-8 my-8">
                  <h2 className="text-xl font-bold mb-2 text-gray-300">
                    {project.title}
                  </h2>
                  <hr className="opacity-20 my-4"></hr>
                  <small className="text-gray-300">{project.description}</small>
                </div>
                <div className="absolute bottom-4 right-6">
                  <div className="flex justify-end gap-2 ">
                    {project.isDeployed ? (
                      <a
                        href={project.link}
                        rel="noreferrer"
                        target="_blank"
                        className="shadow-xl flex gap-1 border border-transparent py-1 px-2 rounded-md active:bg-white active:text-white hover:shadow-xl hover:border-gray-100 bg-[#333]"
                      >
                        <img
                          width="22"
                          height="22"
                          className="webb hover:border bg-white rounded"
                          src="https://img.icons8.com/pastel-glyph/64/website--v2.png"
                          alt="website--v2"
                        />
                        <div className="text-[10px] grid place-items-center font-bold">
                          SITE
                        </div>
                      </a>
                    ) : (
                      false
                    )}
                    {project.hasGIT ? (
                      <a
                        href={project.gitLink}
                        rel="noreferrer"
                        target="_blank"
                        className="shadow-xl flex gap-2 border border-transparent py-1 px-2 rounded-md active:bg-white active:text-white hover:border-gray-100 bg-[#333]"
                      >
                        <svg
                          className="webb "
                          xmlns="http://www.w3.org/2000/svg"
                          height="18"
                          width="18"
                          viewBox="0 0 496 512"
                        >
                          <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                        </svg>
                        <div className="text-xs grid place-items-center font-bold ">
                          Repository
                        </div>
                      </a>
                    ) : (
                      false
                    )}
                    {project.withTeam ? (
                      <div>
                        <a className="my-anchor-element">
                          <img src={group} alt="group" className="w-7 h-7" />
                        </a>
                        <Tooltip anchorSelect=".my-anchor-element">
                          With a team.
                        </Tooltip>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="text-xs w-full flex flex-wrap my-4 gap-1 px-4">
              {project.devStack.map((stack, index) => (
                <ul key={index} className="">
                  <div className="shadow-lg rounded px-1"><span className="text-lg">🖈</span>{stack}
                    </div> 
                    
                </ul>
              ))}
            </div> */}
            </div>

            // <div :style="'background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(' + constructImg + '); background-position: 0% 100%; background-attachment: fixed; background-size: cover; '">
            //   </div>
          ))}
        </div>
      </MotionAnimate>
    </div>
  );
};

export default Projects;
