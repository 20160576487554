import React from "react";
// import Lottie from "lottie-react";
// import web from "../assets/web.json";
// import { useRef } from "react";
// import ako from "../assets/abi logo .png";
import TypeIt from "typeit-react";
import Skills from "./skills";
// import me from '../assets/5.png'
import { MotionAnimate } from 'react-motion-animate'
import Experienece from "./experience";
import Projects from "./projects";
import linkedin from "../assets/linkedin2.svg";
import gmail from '../assets/gmail-svgrepo-com.svg'

  
const Home = () => {
  // const LottieAnimation = useRef();
  const linkedInLink = "https://www.linkedin.com/in/josephreytolentino/";
  const githubLink = "https://github.com/abi-collab";
  const gmailLink = "https://mail.google.com/mail/u/0/?fs=1&to=josephreytolentino@gmail.com&tf=cm";

  return (
    <div>

      <div className="z-100 xs:w-full sm:w-1/2 md:w-1/3 mx-auto ">
        <div>
          <div className="font-bold text-5xl md:text-6xl plainBlack">
            <TypeIt
              getBeforeInit={(instance) => {
                instance
                  .pause(2000)
                  .type(`Josephray`)
                  .pause(1000)
                  .delete(3)
                  .pause(1000)
                  .type(` Rey Amper Tolention`)
                  .pause(1000)
                  .delete(2)
                  .type(`no`);
                return instance;
              }}
              options={{
                speed: 30,
              }}
              className="plainBlack"
            ></TypeIt>
          </div>
          <div className="flex justify-start gap-4 my-8">
            <div className="text-2xl md:text-3xl font-bold plainBlack grid place-content-center animate-bounce">
              <a href={linkedInLink} rel="noreferrer" target="_blank">
                <img src={linkedin} alt="linkedIn Icon" className="webb" height="32" width="32" />
              </a>
            </div>
            <div className="text-2xl md:text-3xl font-bold plainBlack grid place-content-center animate-spin duration-3000">
            <a href={githubLink} rel="noreferrer" target="_blank">
               <svg
                      className="webb hover:border border-transparent"
                      xmlns="http://www.w3.org/2000/svg"
                      height="32"
                      width="32"
                      viewBox="0 0 496 512"
                    >
                      <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                    </svg>
              </a>
           
            </div>
            <div className="text-2xl md:text-3xl font-bold plainBlack grid place-content-center animate-ping">
            <a href={gmailLink} rel="noreferrer" target="_blank">
            <img src={gmail} alt="linkedIn Icon" className="webb" height="32"
                      width="32" />
                      </a>
            </div>
          </div>
       
          <MotionAnimate animation='fadeInUp'
                        reset={false}
                        distance={200}
                        speed={1}>
   
        A versatile software engineer with years of professional experience and
            a passion for creating innovative software solutions. Strong
            problem-solving abilities and a collaborative mindset. Seeking a
            challenging opportunity to contribute my expertise and drive the
            success of a dynamic organization.
      
      </MotionAnimate>
       
        </div>
<Skills />
        {/* Add your content and components here */}
       
      </div>
      <div className="xs:w-full md:w-1/2 mx-auto">
        <Experienece />
        <hr className="my-24 opacity-30"></hr>
      <Projects />
        </div>
      
    </div>
  );
};

export default Home;
